import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { router } from './router'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {

      token: '',
      barColor: '#fe3c00',
      // barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
      drawer: null,
      // token: null, did not work well
  },
  mutations: {

      SET_BAR_IMAGE (state, payload) {
        state.barImage = payload
      },
      SET_DRAWER (state, payload) {
        state.drawer = payload
      },
      SET_TOKEN (state, payload) {
        state.token = payload
      },
  },
  actions: {

  },
})

export default store
